import React from 'react'
import PropTypes from 'prop-types'

// Gatsby
import {
    Link,
} from "gatsby"


// Styles
import "./HomePageTopicSection.scss"

const HomePageTopicSection = ({
    category,
    heading,
    body,
    buttonText,
    buttonTo,
    illustration
}) => {
    //console.log(illustration);
    
    return (
        <section id="HomePageTopicSection">

            <div className="lhs">
                
                <h3>
                    {category}
                </h3>

                <h2>
                    {heading}
                </h2>

                <p>
                    {body}
                </p>

                {buttonText && buttonTo &&
                <div className="buttons">
                    <Link
                        to={buttonTo} 
                        className="button button-primary"
                    >
                        {buttonText}
                    </Link>
                </div>
                }

            </div>

            <div className="rhs">
                {illustration && 
                <img
                    src={illustration}
                    alt={heading}
                />
                }
            </div>

        </section>
    )
}

HomePageTopicSection.propTypes = {
    category: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
}

export default HomePageTopicSection
