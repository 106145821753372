import { Link } from 'gatsby';
import React from 'react'

import illustration from '../../../content/assets/portraits/homepage-profile-ben.png';

// Styles
import "./HomePageStorySection.scss"

const HomePageStorySection = () => {

    return (
        <section id="HomePageStorySection">

            <div className="inner">

                <div className="lhs">

                    <h3>
                        My Indie Dev Story
                    </h3>

                    <h2>
                        Hi, I'm Ben
                        <small>from GameDevMix.</small>
                    </h2>

                    <p>
                        I'm an aspiring indie game developer, currently working on <Link to={"supersnowtubes"}>Super Snow Tubes</Link>, a satisfyingly cute, retro-inspired snow racer, starring an adorable animal cast in a fun and fierce battle for 1st place.
                    </p>
                    <p>
                        It's my very first, completely original indie game. And I am so excited to be developing it in Unity!
                    </p>
                    {/* <p>
                        It's been a long journey, and I've got a long way left to go, but hey, nobody said making games was gonna be easy.
                    </p> */}
                    <p>
                        With this website, I want to share the learnings on my personal game development journey; with the goal of educating, entertaining and inspiring others along the way. Like you?
                    </p>

                    {/* 
                    <p>
                        Helping aspiring indie game developers bring their game ideas to life with Unity.
                    </p> */}

                </div>

                <div className="rhs">
                    {illustration &&
                        <img
                            src={illustration}
                            alt="Ben"
                        />
                    }
                </div>

            </div>

        </section>
    )
}

export default HomePageStorySection
