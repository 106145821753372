// React
import React from "react"

// Gatsby
import {
    Link,
    graphql
} from "gatsby"

// Images
import Image from "gatsby-image"
// import illustrationSnowtubes from '../../content/assets/homepage-illustration-snowtubes.png';
import illustrationResources from '../../content/assets/homepage-illustration-resources.png';

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Styles
import "../styles/pages/HomePage.scss"

// Nested components
import HomePageStorySection from "../components/homepage/HomePageStorySection"
import HomePageTopicSection from "../components/homepage/HomePageTopicSection"
import HomePageVideosSection from "../components/homepage/HomePageVideosSection"
import HomePagePostsSection from "../components/homepage/HomePagePostsSection"
import LinkExt from "../components/linkext";

// Home page component
const BlogIndex = ({
    data
}) => {

    // Page data
    const pageTitle = 'Welcome';
    const pageDescription = 'Helping you bring your game ideas to life with Unity.';

    // Latest three articles from the blog
    const blogPosts = data.allMdx.edges;

    // Latest three public videos from my YouTube channel
    const youtubeVideos = null;//data.allYoutubeVideo?.edges;

    // Render
    return (
        <Layout>

            <SEO
                title={pageTitle}
                description={pageDescription}
            // thumbnail={shareThumbnail}
            />

            <div id="home-page">

                <header className="above-fold">

                    <section className="text">

                        <div className="label">
                            Currently working on...
                        </div>

                        <h1>
                            {/* Learn how games<br/>
                            are made */}
                            {/* Game ideas<br/>
                            brought to life */}
                            Super Snow Tubes
                        </h1>

                        <p className="description">
                            {/* Helping aspiring indie game developers bring their game ideas to life with Unity. */}
                            {/* A satisfyingly cute, retro-inspired snow racer, starring a cast of quirky and adorable animal buddies battling each other for first place in a race through the seasons. Ready, set, go! */}
                            {/* A satisfyingly cute, retro-inspired snow racer, starring an adorable animal cast in a fun and fierce battle for 1st place. Ready, set, go! */}
                            
                            Take to the mountains in this satisfyingly cute, retro-inspired snow racer, starring an adorable animal cast in a fun and fierce battle for first place. Ready, set, go!
                        </p>

                        <div className="buttons">

                            <LinkExt
                                to={'https://store.steampowered.com/app/1515590/Super_Snow_Tubes'}
                                className="button button-primary"
                            >
                                Wishlist on Steam
                            </LinkExt>

                            <Link
                                to={'/supersnowtubes'}
                                className="button button-secondary"
                            >
                                Learn more
                            </Link>

                        </div>

                    </section>

                    <section className="image">

                        <Image
                            // fixed={data.avatar.childImageSharp.fixed}
                            fluid={data.avatar.childImageSharp.fluid}
                            alt="Illustration"
                        />

                    </section>

                </header>

                {/* Story */}
                <HomePageStorySection />

                {/* Latest Blog Posts */}
                <HomePagePostsSection
                    posts={blogPosts}
                />

                {/* Latest YouTube Videos */}
                {/* <HomePageVideosSection
                    videos={youtubeVideos}
                /> */}

                {/* Snow Tubes */}
                {/* <HomePageTopicSection
                    category="Mobile Game Devlog"
                    heading="Making Super Snow Tubes"
                    body={`Follow the planning, design and development of "Super Snow Tubes", a winter sports downhill action arcade game for mobile, inspired by genre classics such as "Ski or Die" (MS-DOS) and "Snowboard Kids" (N64). It's my first ever mobile game!`}
                    buttonText="Watch Devlog"
                    buttonTo={`/supersnowtubes`}
                    illustration={illustrationSnowtubes}
                /> */}

                {/* MixtroidVania */}
                {/* <HomePageTopicSection
                    category="Unity Tutorial Series"
                    heading="Making MixtroidVania"
                    body={`Learn how to create an awesome MetroidVania Action Adventure Platformer game in Unity using Free Assets!`}
                    buttonText="Watch Tutorials"
                    buttonTo={`/mixtroidvania`}
                // illustration={illustrationSnowtubes}
                /> */}

                {/* Platformer Guide */}
                {/* <HomePageTopicSection
                    category="Tips, Tricks &amp; Tutorials"
                    heading="2D Platformer Character Controller"
                    body="Lorem ipsum dolor sit amet consectetur adipisicing elit. A deleniti quam ducimus eos architecto commodi? Esse, suscipit architecto earum, assumenda dolorem adipisci, minima magni dicta voluptatem officiis repellat dolor eaque!"
                    buttonText="Browse Platformer Guide"
                    buttonTo={`/platformerguide`}
                /> */}

                {/* Enemies in Games */}
                {/* <HomePageTopicSection
                    category="Tips, Tricks &amp; Tutorials"
                    heading="Enemies in Games"
                    body="Lorem ipsum dolor sit amet consectetur adipisicing elit. A deleniti quam ducimus eos architecto commodi? Esse, suscipit architecto earum, assumenda dolorem adipisci, minima magni dicta voluptatem officiis repellat dolor eaque - as we create frightening foes to fight."
                    buttonText="Browse Enemies Guide"
                    buttonTo={`/enemies`}
                /> */}

                {/* Resources */}
                <HomePageTopicSection
                    category="Tools, Tutorials, Courses &amp; More"
                    heading="Resources"
                    body="Everything you need to start your game development journey today, or to help you get to the next level."
                    buttonText="Browse Resources"
                    buttonTo={`/resources`}
                    illustration={illustrationResources}
                />


            </div>

        </Layout>
    )
}

// Export component
export default BlogIndex

// GraphQL query
export const pageQuery = graphql`
    query {  
        #avatar: file(absolutePath: { regex: "/homepage-dino.png/" }) {
        avatar: file(absolutePath: { regex: "/sst/sst-characters-thumb.png/" }) {
            childImageSharp {
                fluid(srcSetBreakpoints: [270, 300]) {
                    ...GatsbyImageSharpFluid
                }
                resize(width: 1200, height: 1200) {
                    src
                }                
                # fixed(width: 280, height: 280) {
                #     ...GatsbyImageSharpFixed
                #}
            }
        }      
        site {
            siteMetadata {
                title
            }
        }        
        #allYoutubeVideo(filter: {privacyStatus: {eq: "public"}}, limit: 3, sort: {fields: publishedAt, order: DESC}) {
            #edges {
                #node {
                    #title
                    #privacyStatus
                    #publishedAt(formatString: "MMM D, YYYY")
                    #thumbnail {
                        #url
                        #}
                        #videoId
                        #description
                        #}
                        #}
                        #}
        allMdx(
            filter: { 
                fileAbsolutePath: {glob: "**/content/blog/**/**.mdx" },
            }
            sort: { fields: [frontmatter___date], order: DESC }
            limit: 3
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    timeToRead
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        category
                        date(formatString: "MMM D, YYYY")
                        description
                        tags
                        #image {
                            #childImageSharp {
                             #   fluid(maxWidth: 800) {
                              #      ...GatsbyImageSharpFluid
                               # }
                            #}
                        #}            
                    }
                }
            }
        }
    }
`
