// React
import React from 'react'
import PropTypes from 'prop-types'

// Gatsby
import {
    Link,
} from "gatsby"

// Styles
import "./HomePagePostsSection.scss"

// Nested components
import PostCard from '../PostCard'

// Component that displays the latest blog posts
const HomePagePostsSection = ({
    posts
}) => {
    return (
        <section id="HomePagePostsSection">

            <header>

                <div className="button-right">
                    <Link
                        to={'/blog'}
                        className="button button-secondary"
                    >
                        All Posts
                    </Link>
                </div>

                <h3>
                    From the Blog
                </h3>

                <h2>
                    Latest Articles
                </h2>


            </header>

            <main className="posts-list">

                {posts.map(({ node: post }) => (
                    <PostCard
                        post={post}
                        key={post.fields.slug}
                    />
                ))}

            </main>

        </section>
    )
}

// Prop types
HomePagePostsSection.propTypes = {
    posts: PropTypes.array.isRequired
}

// Export
export default HomePagePostsSection
